export default class NewsPostModel {
    constructor(item) {
        this._setTitle(item);
        this._setTitleRu(item);
        this._setDescription(item);
        this._setDescriptionRu(item);
        this._setCategoryId(item);
        this._setImage(item);
        this._setActive(item);
    }

    /**
     * set title
     * @param title
     * @private
     */
    _setTitle({title}) {
        this.title = title;
    }

    /**
     * set title ru
     * @param title_ru
     * @private
     */
    _setTitleRu({title_ru}) {
        this.title_ru = title_ru;
    }

    /**
     * set description
     * @param description
     * @private
     */
    _setDescription({description}) {
        this.description = description;
    }

    /**
     * set description ru
     * @param description_ru
     * @private
     */
    _setDescriptionRu({description_ru}) {
        this.description_ru = description_ru;
    }


    /**
     * set image
     * @param image
     * @private
     */
    _setImage({image}) {
        this.photo = image;
    }

    /**
     * set active
     * @param active
     * @private
     */
    _setActive({active}) {
        this.active = active;
    }

    /**
     * set category id
     * @param category
     * @private
     */
    _setCategoryId({category}) {
        if (category?.id)
            this.categoryId = category.id;
        else this.categoryId = category;
    }
}
